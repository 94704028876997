// src/manholeData.js

export const manholeCovers = [
    { id: 1, image: require('./assets/manhole1.jpg'), location: 'The Hague, The Netherlands' },
    { id: 2, image: require('./assets/manhole2.jpg'), location: 'Bruges, Belgium' },
    { id: 3, image: require('./assets/manhole3.jpg'), location: 'Bruges, Belgium' },
    { id: 4, image: require('./assets/manhole4.jpg'), location: 'Moscow, Russia' },
    { id: 5, image: require('./assets/manhole5.jpg'), location: 'Belgrade, Serbia' },
    { id: 6, image: require('./assets/manhole6.jpg'), location: 'Diyarbakir, Türkiye' },
    { id: 7, image: require('./assets/manhole7.jpg'), location: 'Istanbul, Türkiye' },
    { id: 8, image: require('./assets/manhole8.jpg'), location: 'Moscow, Russia' },
    { id: 9, image: require('./assets/manhole9.jpg'), location: 'Weinheim, Germany' },
    { id: 10, image: require('./assets/manhole10.jpg'), location: 'Mainz, Germany' },
    { id: 11, image: require('./assets/manhole11.jpg'), location: 'Kaliningrad, Russia' },
    { id: 12, image: require('./assets/manhole12.jpg'), location: 'Zelenogradsk, Russia' },
    { id: 13, image: require('./assets/manhole13.jpg'), location: 'Svetlogorsk, Russia' },
    { id: 14, image: require('./assets/manhole14.jpg'), location: 'Sovietsk, Russia' },
    { id: 15, image: require('./assets/manhole15.jpg'), location: 'Chernyakhovsk, Russia' },
    { id: 16, image: require('./assets/manhole16.jpg'), location: 'Budapest, Hungary' },
    { id: 17, image: require('./assets/manhole17.jpg'), location: 'Budapest, Hungary' },
    { id: 18, image: require('./assets/manhole18.jpg'), location: 'Mochimune, Japan' },
    { id: 19, image: require('./assets/manhole19.jpg'), location: 'Mochimune, Japan' },
    { id: 20, image: require('./assets/manhole20.jpg'), location: 'Fujikawaguchiko, Japan' },
    { id: 21, image: require('./assets/manhole21.jpg'), location: 'Mochimune, Japan' },
    { id: 22, image: require('./assets/manhole22.jpg'), location: 'Shizuoka, Japan' },
    { id: 23, image: require('./assets/manhole23.jpg'), location: 'Shizuoka, Japan' },
    { id: 24, image: require('./assets/manhole24.jpg'), location: 'Shizuoka, Japan' },
    { id: 25, image: require('./assets/manhole25.jpg'), location: 'Osaka, Japan' },
    { id: 26, image: require('./assets/manhole26.jpg'), location: 'Osaka, Japan' },
    { id: 27, image: require('./assets/manhole27.jpg'), location: 'Osaka, Japan' },
    { id: 28, image: require('./assets/manhole28.jpg'), location: 'Osaka, Japan' },
    { id: 29, image: require('./assets/manhole29.jpg'), location: 'Kotor, Montenegro' },
    { id: 30, image: require('./assets/manhole30.jpg'), location: 'Nara, Japan' },
    // Add more manhole covers here
  ];