import React, { useState, useEffect, useCallback } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ArrowLeft, ThumbsUp, ThumbsDown } from 'lucide-react';
import { manholeCovers } from './manholeData';

function App() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [liked, setLiked] = useState([]);
  const [disliked, setDisliked] = useState([]);
  const [history, setHistory] = useState([]);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const currentManhole = manholeCovers[currentIndex];

  const handleSwipe = useCallback((direction) => {
    setHistory([...history, { index: currentIndex, action: direction }]);
    if (direction === 'right') {
      setLiked([...liked, currentManhole.id]);
    } else {
      setDisliked([...disliked, currentManhole.id]);
    }
    setCurrentIndex((prevIndex) => (prevIndex + 1) % manholeCovers.length);
  }, [currentIndex, currentManhole.id, history, liked, disliked]);

  const handleBack = () => {
    if (history.length > 0) {
      const lastAction = history.pop();
      setHistory([...history]);
      setCurrentIndex(lastAction.index);
      if (lastAction.action === 'right') {
        setLiked(liked.filter(id => id !== manholeCovers[lastAction.index].id));
      } else {
        setDisliked(disliked.filter(id => id !== manholeCovers[lastAction.index].id));
      }
    }
  };

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'ArrowLeft') {
      handleSwipe('left');
    } else if (e.key === 'ArrowRight') {
      handleSwipe('right');
    }
  }, [handleSwipe]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;
    if (isLeftSwipe || isRightSwipe) {
      handleSwipe(isLeftSwipe ? 'left' : 'right');
    }
  };

  const totalViewed = liked.length + disliked.length;
  const likedPercentage = totalViewed > 0 ? (liked.length / totalViewed * 100).toFixed(1) : 0;
  const dislikedPercentage = totalViewed > 0 ? (disliked.length / totalViewed * 100).toFixed(1) : 0;

  const buttonStyle = {
    padding: '0.5rem 1rem',
    border: 'none',
    borderRadius: '0.25rem',
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    fontWeight: 'bold',
    color: 'white',
  };

  return (
    <HelmetProvider>
    <div className="App" style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'flex-start', 
      minHeight: '100vh', 
      padding: '0.5rem', 
      backgroundColor: '#f0f0f0',
      boxSizing: 'border-box'
    }}>
      <Helmet>
        <title>Manhole Cover Tinder | Discover Unique Covers</title>
        <meta name="description" content="Explore and rate unique manhole covers from around the world in this Tinder-style app. Swipe through a global collection of urban art beneath your feet!" />
      </Helmet>
      
      <div style={{ width: '100%', maxWidth: '400px', marginBottom: '0.1rem' }}>
        <div style={{ textAlign: 'center', fontSize: '0.8rem', color: '#181818', marginBottom: '1.3rem', lineHeight: '0.4rem' }}>
          <h1>Manhole Cover Tinder</h1>
          <p>Swipe left or right, or use arrow keys on desktop.</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
          <div style={{ ...buttonStyle, backgroundColor: '#D55587', width: '41%', justifyContent: 'center', borderRadius: '0.4rem', fontSize: '0.7rem' }}>
            <ThumbsDown size={14} />
            Disliked: {disliked.length} ({dislikedPercentage}%)
          </div>
          <div style={{ ...buttonStyle, backgroundColor: '#58B07B', width: '41%', justifyContent: 'center', borderRadius: '0.4rem', fontSize: '0.7rem' }}>
            <ThumbsUp size={14} />
            Liked: {liked.length} ({likedPercentage}%)
          </div>
        </div>
      </div>
      <div 
        style={{ 
          width: '100%', 
          maxWidth: '400px', 
          height: 'calc(90vh - 180px)', 
          position: 'relative', 
          borderRadius: '0.75rem', 
          overflow: 'hidden', 
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          touchAction: 'pan-y'
        }}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <img
          src={currentManhole.image}
          alt={currentManhole.location}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
        <div style={{ 
          position: 'absolute', 
          bottom: 0, 
          left: 0, 
          right: 0, 
          padding: '1rem', 
          background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)'
        }}>
          <p style={{ color: 'white', fontSize: '1.25rem', fontWeight: '600' }}>{currentManhole.location}</p>
        </div>
      </div>
      {/*<button 
        onClick={handleBack} 
        style={{ 
          marginTop: '1rem', 
          padding: '0.5rem 1rem', 
          backgroundColor: 'white', 
          border: '1px solid #ccc', 
          borderRadius: '0.25rem', 
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem'
        }}
        disabled={history.length === 0}
      >
        <ArrowLeft size={16} /> Back
      </button>*/}
    </div>
    </HelmetProvider>
  );
}

export default App;